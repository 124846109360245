
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    treeData: Object,
  },
})
export default class FamilyTree extends Vue {
  treeData!: any;
  radial = false;
  depth = 1;

  get options(): any {
    const result: any = {
      tooltip: {
        trigger: "item",
        triggerOn: "mousemove",
      },
      series: [
        {
          type: "tree",
          data: [this.treeData],
          symbol: "emptyCircle",
          symbolSize: 7,
          textSize: 20,
          initialTreeDepth: this.depth,
          animationDurationUpdate: 750,
          emphasis: {
            focus: "descendant",
          },
          label: {
            fontSize: "20",
          },
        },
      ],
    };
    if (this.radial) {
      result.series[0].layout = "radial";
    } else {
      result.series[0].orient = "LR";
      result.series[0].layout = "orthogonal";
    }
    return result;
  }

  get selectDepths(): number[] {
    const result = [-1, 0, 1, 2, 3];
    if (this.depth > 3) {
      result.push(this.depth);
    }
    return result;
  }

  getDepthName(depth: number): string {
    if (depth == -1) {
      return "Vse";
    }
    if (depth < 3) {
      return ["Oce in mati", "Otroci", "Vnuki"][depth];
    }
    return "Pra-" + "pra-".repeat(depth - 3) + "vnuki";
  }
}
